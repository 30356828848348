<template>
        <v-container id="resources" fluid tag="section" style="padding-bottom: 150px">
        <v-card 
          v-show="user.roles !== 'business' && user.roles !== 'orgUser' && user.roles !== 'orgAdmin'" 
          cols="12" md="8">
          <v-card-text>
            <v-alert
              border="left"
              color="blue"
              elevation="8"
              icon="mdi-lock"
              prominent
              type="warning"
            >
              Unfortunately, you are not currently subscribed to our Business Plans. 
              <br/>
              Please visit your account page to alter your subscription to purchase access to this feature.  
            </v-alert> 
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  class="mb-4"
                    to="/pages/user"
                  >
                    Open My Account
            </v-btn>
          </v-card-actions>
        </v-card>
            <v-overlay :value="overlay"></v-overlay>
            <v-row align="center" justify="center">
            <v-col cols="12" md="8">
                <base-material-card
                  v-show="user.roles === 'business' || user.roles === 'orgUser' || user.roles === 'orgAdmin'" 
                >
                    <template v-slot:heading>
                        <div class="text-h3 font-weight-light">
                        Resources
                        </div>

                        <div class="text-subtitle-1 font-weight-light">
                        Links to external sites where you can get more information
                        </div>
                    </template>

                    <!-- <v-card-title :key="i">Links</v-card-title> -->

                    <template v-for="(resource, i) in resources">
                        <!-- <v-card-title :key="i">{{resource.name}}</v-card-title> -->
                        <v-card-text :key="i">
                                <a style="font-weight: bold;" 
                                :href="resource.url"
                                target="_blank">
                                {{resource.name}}
                                </a>
                        </v-card-text>
                    </template>
                </base-material-card>
            </v-col>
            </v-row>
        </v-container>
</template>

<script>
import { mapState } from "vuex";
import "firebase/auth";

export default {
  name: "Resources",

  data() {
    return {
      debug: false,
      overlay: false,
      resources: [{
          name: "Title Document Search",
          url: "https://search.titlesqld.com.au/product-search"
      },
      {
          name: "Survey Plan Search",
          url: "https://search.titlesqld.com.au/product-search"
      },
      {
          name: "Contaminated Land Search",
          url: "https://products.des.qld.gov.au/shopping/home.action"
      },
      {
          name: "Easement/Covenant Document Search",
          url: "https://search.titlesqld.com.au/product-search"
      },
      {
          name: "Statutory Land Valuation Search",
          url: "https://www.resources.qld.gov.au/qld/environment/land/title/valuation/search"
      },
      {
          name: "Brisbane Development.i",
          url: "https://developmenti.brisbane.qld.gov.au/"
      },
      {
          name: "QLD State Heritage Register",
          url: "https://apps.des.qld.gov.au/heritage-register/"
      },
      {
          name: "Brisbane Heritage Register",
          url: "http://heritage.brisbane.qld.gov.au/"
      },
      {
          name: "Brisbane FloodWise Report",
          url: "http://floodinformation.brisbane.qld.gov.au/fio/floodwisesearch"
      },
      {
          name: "QImagery (Historic Mapping)",
          url: "https://qimagery.information.qld.gov.au/"
      }
      ]
    };
  },

  computed: {
    ...mapState(["user"]),
  },
  
  created() {
    // this.$store.dispatch("retrieveRoles");
    this.$store.dispatch("retrieveSubscriptions");
  },

}
</script>

<style>

</style>